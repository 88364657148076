<template>
    <nav class="navbar navbar-expand-xl bg-light border-bottom mb-3">
        <div class="container-fluid">
            <router-link
                :to="{ name: 'home' }"
                style="margin-right: 8px !important"
                class="navbar-brand d-flex align-items-center"
            >
                <img
                    :src="require('@/assets/gimconworkforce.png')"
                    alt="Gimcon Workforce Logo"
                    height="30"
                    class="d-inline-block position-relative me-1"
                />
                <span class="text-uppercase d-inline-block">Gimbel</span>
                <small class="text-secondary d-inline-block ms-1">
                    workforce
                </small>
                <EnvironmentBadge
                    css-style="font-size: 0.8rem"
                    class="ms-4"
                />
            </router-link>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon" />
            </button>

            <div
                id="navbarScroll"
                class="ms-4 collapse navbar-collapse"
            >
                <ul
                    class="navbar-nav me-auto text-xl-center align-items-xl-center"
                >
                    <HasRole sub-string="siteacceptance.">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                :class="{
                                    'router-link-active':
                                        isRoute('site-acceptance'),
                                }"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Site-Acceptance
                            </a>
                            <ul class="dropdown-menu">
                                <HasRole :role="UserRoles.SITEACCEPTANCE_USER">
                                    <li>
                                        <router-link
                                            :to="{ name: 'tasks-list' }"
                                            class="dropdown-item"
                                        >
                                            Aufgaben
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'subscribe-lists' }"
                                            class="dropdown-item"
                                        >
                                            Listen
                                        </router-link>
                                    </li>
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                </HasRole>
                                <HasRole
                                    :role="UserRoles.SITEACCEPTANCE_HOL_EXTERN"
                                >
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'my-tasks-grid-hol-extern',
                                                params: {
                                                    projectId: 'ranfunctional',
                                                    flag: 'Holistic.extern',
                                                },
                                            }"
                                            class="dropdown-item"
                                        >
                                            Aufgaben - Extern - RAN Functional
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'my-tasks-grid-hol-extern',
                                                params: {
                                                    projectId:
                                                        'ranfunctional.mangel',
                                                    flag: 'Holistic.mangel.extern',
                                                },
                                            }"
                                            class="dropdown-item"
                                        >
                                            Aufgaben - Extern - RAN Functional
                                            Mangel
                                        </router-link>
                                    </li>
                                </HasRole>
                                <HasRole
                                    :role="UserRoles.SITEACCEPTANCE_MANAGEMENT"
                                >
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 's.a/management' }"
                                            class="dropdown-item"
                                        >
                                            Verwaltung
                                        </router-link>
                                    </li>
                                </HasRole>
                                <HasRole :role="UserRoles.SF_USER">
                                    <li>
                                        <router-link
                                            :to="{ name: 'sonder-freigaben' }"
                                            class="dropdown-item"
                                        >
                                            Sonderfreigaben
                                        </router-link>
                                    </li>
                                </HasRole>
                                <HasRole :role="UserRoles.SITEACCEPTANCE_USER">
                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'history' }"
                                            class="dropdown-item"
                                        >
                                            Historie
                                        </router-link>
                                    </li>
                                </HasRole>
                            </ul>
                        </li>
                    </HasRole>
                    <HasRole :role="UserRoles.ATC_USER">
                        <li class="nav-item">
                            <router-link
                                :to="{ name: 'atc-trackinglist' }"
                                class="nav-link"
                            >
                                ATC Trackinglist
                            </router-link>
                        </li>
                    </HasRole>
                    <HasRole :role="UserRoles.DB_USER">
                        <li class="nav-item">
                            <router-link
                                :to="{ name: 'db-trackinglist' }"
                                class="nav-link"
                                :class="{
                                    'router-link-active':
                                        isRoute('db-trackinglist'),
                                }"
                            >
                                DB Trackinglist
                            </router-link>
                        </li>
                    </HasRole>
                    <HasRole sub-string="dfmg.">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                :class="{
                                    'router-link-active':
                                        isRoute('dfmg-trackinglist'),
                                }"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                DFMG Trackinglist
                            </a>
                            <ul class="dropdown-menu">
                                <HasRole :role="UserRoles.DFMG_USER">
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'trackinglist-offsite',
                                            }"
                                            class="dropdown-item"
                                        >
                                            Trackinglist Offsite
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'trackinglist-onsite',
                                            }"
                                            class="dropdown-item"
                                        >
                                            Trackinglist Onsite
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'trackinglist-mastwagen',
                                            }"
                                            class="dropdown-item"
                                        >
                                            Trackinglist Mastwagen
                                        </router-link>
                                    </li>
                                </HasRole>
                            </ul>
                        </li>
                    </HasRole>
                    <HasRole :role="UserRoles.NOTE_USER">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                :class="{
                                    'router-link-active': isRoute('nokia'),
                                }"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Nokia
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link
                                        :to="{ name: 'nokia-oran113' }"
                                        class="nav-link"
                                        :class="{
                                            'router-link-active':
                                                isRoute('nokia-oran113'),
                                        }"
                                    >
                                        Nokia ORAN113
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        :to="{ name: 'nokia-health-safety' }"
                                        class="nav-link"
                                        :class="{
                                            'router-link-active': isRoute(
                                                'nokia-health-safety',
                                            ),
                                        }"
                                    >
                                        Nokia Health &amp; Safety
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        :to="{ name: 'nokia-mw-audits' }"
                                        class="nav-link"
                                        :class="{
                                            'router-link-active':
                                                isRoute('nokia-mw-audits'),
                                        }"
                                    >
                                        Nokia MW Audits
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link
                                        :to="{ name: 'nokia-oran3000' }"
                                        class="nav-link"
                                        :class="{
                                            'router-link-active':
                                                isRoute('nokia-oran3000'),
                                        }"
                                    >
                                        Nokia ORAN3000
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </HasRole>

                    <HasRole :role="UserRoles.ONSITE_USER">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                :class="{
                                    'router-link-active':
                                        isRoute('onsite-planning'),
                                }"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Onsite
                            </a>
                            <ul class="dropdown-menu">
                                <HasRole :role="UserRoles.ONSITE_USER">
                                    <li>
                                        <router-link
                                            :to="{ name: 'onsite-plan' }"
                                            class="dropdown-item"
                                        >
                                            Onsite Drafting
                                        </router-link>
                                    </li>

                                    <li>
                                        <hr class="dropdown-divider" />
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'onsite-ranohneinfra',
                                            }"
                                            class="dropdown-item"
                                        >
                                            RAN ohne Infra Trackinglist
                                        </router-link>
                                    </li>

                                    <li>
                                        <router-link
                                            :to="{ name: 'onsite-ran' }"
                                            class="dropdown-item"
                                        >
                                            RAN Trackinglist
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'onsite-mw' }"
                                            class="dropdown-item"
                                        >
                                            MW Trackinglist
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'onsite-nrg' }"
                                            class="dropdown-item"
                                        >
                                            NRG Trackinglist
                                        </router-link>
                                    </li>
                                </HasRole>
                            </ul>
                        </li>
                    </HasRole>
                    <HasRole :role="UserRoles.VERSATEL_USER">
                        <li class="nav-item">
                            <router-link
                                :to="{ name: 'versatel' }"
                                class="nav-link"
                                :class="{
                                    'router-link-active': isRoute('versatel'),
                                }"
                            >
                                Versatel
                            </router-link>
                        </li>
                    </HasRole>
                    <HasRole sub-string="hesa.">
                        <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                :class="{
                                    'router-link-active':
                                        isRoute('/health-safety'),
                                }"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Health &amp; Safety
                            </a>
                            <ul class="dropdown-menu">
                                <HasRole :role="UserRoles.HESA_USER">
                                    <li>
                                        <router-link
                                            :to="{ name: 'hesa.ubersicht' }"
                                            class="dropdown-item"
                                        >
                                            Übersicht
                                        </router-link>
                                    </li>
                                    <li class="dropdown-divider" />
                                    <li>
                                        <router-link
                                            :to="{
                                                name: 'hesa.unterweisungsliste',
                                            }"
                                            class="dropdown-item"
                                        >
                                            Unterweisungsliste
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'hesa.psa' }"
                                            class="dropdown-item"
                                        >
                                            PSA
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'hesa.werkzeug' }"
                                            class="dropdown-item"
                                        >
                                            Werkzeug
                                        </router-link>
                                    </li>
                                    <li>
                                        <router-link
                                            :to="{ name: 'hesa.abnahmen' }"
                                            class="dropdown-item"
                                        >
                                            Abnahmen
                                        </router-link>
                                    </li>
                                </HasRole>
                            </ul>
                        </li>
                    </HasRole>
                </ul>

                <div
                    v-if="secondsUntilLogout < 60 * 30"
                    class="nav-item"
                >
                    <span
                        v-if="secondsUntilLogout"
                        class="badge text-bg-warning"
                    >
                        Logout in
                        {{ Math.ceil(secondsUntilLogout / 60) }} Minuten
                    </span>
                </div>

                <ul
                    class="navbar-nav ms-auto text-xl-center align-items-xl-center"
                >
                    <!-- Christmastime enable/disable button -->
                    <li
                        v-if="isItChristmastime"
                        class="nav-item"
                    >
                        <a
                            class="nav-link fs-7 position-relative"
                            role="button"
                            style="top: 2px"
                            @click.prevent="christmastimeBtnClick"
                        >
                            <img
                                :src="require('@/assets/itschristmasbaby.svg')"
                                class="position-relative me-1"
                                height="20"
                                width="20"
                                style="top: -4px"
                                alt="it's christmas baby :)"
                            />
                            Weihnachtsmodus
                        </a>
                    </li>
                    <li
                        v-else-if="isItEasternTime"
                        class="nav-item"
                    >
                        <a
                            class="nav-link fs-7 position-relative"
                            role="button"
                            style="top: 2px"
                            @click.prevent="easterntimeBtnClick"
                        >
                            <img
                                :src="
                                    require('@/assets/easter/easter-egg-(13).svg')
                                "
                                class="position-relative me-1"
                                height="20"
                                width="20"
                                style="top: -4px"
                                alt="it's Eastern baby :)"
                            />
                            EasternModus
                        </a>
                    </li>
                    <li
                        v-else-if="isItUEFATime"
                        class="nav-item"
                    >
                        <a
                            class="nav-link fs-7 position-relative"
                            role="button"
                            style="top: 2px"
                            @click.prevent="UEFATimeBtnClick"
                        >
                            EM Modus
                        </a>
                    </li>

                    <li class="nav-item dropdown">
                        <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            {{ username }}
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <HasRole :role="UserRoles.WF_ADMIN">
                                <li>
                                    <router-link
                                        :to="{ name: 'milestones-dashboard' }"
                                        class="dropdown-item"
                                    >
                                        Milestones Dashboard
                                    </router-link>
                                </li>
                                <li>
                                    <router-link
                                        :to="{ name: 'administration-user' }"
                                        class="dropdown-item"
                                    >
                                        User Administration
                                    </router-link>
                                </li>
                                <li class="dropdown-divider" />
                            </HasRole>
                            <li>
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click.prevent="logout"
                                    >Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <ItsChristmasBaby v-if="showChristmasJewellery && isItChristmastime" />
        <ItsEasternEgg v-if="showEasternJewellery && isItEasternTime" />
    </nav>
</template>

<script>
import { useRoute } from "vue-router"

import EnvironmentBadge from "@/components/EnvironmentBadge.vue"
import HasRole from "@/components/HasRole.vue"
import ItsChristmasBaby from "@/components/ItsChristmasBaby.vue"
import ItsEasternEgg from "@/components/ItsEasternEgg.vue"
import { store } from "@/services/store.service"
import { ui } from "@/services/ui.service"
import { default as service } from "@/services/user.service"
import { UserRoles } from "@/user_roles"

export default {
    name: "NavbarComponent",
    components: { ItsChristmasBaby, ItsEasternEgg, HasRole, EnvironmentBadge },
    data() {
        return {
            showChristmasJewellery: false,
            showEasternJewellery: false,
            active_tab: "my-tasks",
            secondsUntilLogout: false,
        }
    },
    computed: {
        UserRoles() {
            return UserRoles
        },
        username() {
            return store.user?.username
        },
        isItChristmastime() {
            return ui.isItChristmastime()
        },
        isItEasternTime() {
            return ui.isItEasterntime()
        },
        isItUEFATime() {
            return ui.isItUEFAtime()
        },
    },
    mounted() {
        this.showChristmasJewellery = service.settingsGet("isChristmasBaby")
        this.showEasternJewellery = service.settingsGet("isEasternEgg")
        this.showUEFAbg = service.settingsGet("isUEFAbg")
        if (
            (this.showChristmasJewellery && this.isItChristmastime) ||
            (this.showEasternJewellery && this.isItEasternTime)
        ) {
            ui.setPublicHoliday()
        } else {
            ui.removePublicHoliday()
        }
        if (this.showUEFAbg && this.isItUEFATime) {
            document.body.classList.add("navbar-bg")
        } else {
            document.body.classList.remove("navbar-bg")
        }
        this.calculateTimeUntilLogout()
        setInterval(() => {
            this.secondsUntilLogout = this.calculateTimeUntilLogout()
            if (this.secondsUntilLogout < 0) {
                service.logout()
            }
        }, 1000 * 60)
    },
    methods: {
        christmastimeBtnClick() {
            this.showChristmasJewellery = !this.showChristmasJewellery
            service.settingsSet("isChristmasBaby", this.showChristmasJewellery)
            ui.toggleChristmastime()
        },
        easterntimeBtnClick() {
            this.showEasternJewellery = !this.showEasternJewellery
            service.settingsSet("isEasternEgg", this.showEasternJewellery)
            ui.toggleChristmastime()
        },
        UEFATimeBtnClick() {
            this.showUEFAbg = !this.showUEFAbg
            service.settingsSet("isUEFAbg", this.showUEFAbg)
            if (this.showUEFAbg) {
                document.body.classList.add("navbar-bg")
            } else {
                document.body.classList.remove("navbar-bg")
            }
        },
        /**
         * Time until logout in seconds
         * @return {number}
         */
        calculateTimeUntilLogout() {
            const logoutAfterMinutes = 60 * 11 // after this time the user will be logged out ( 11 hours )
            const logoutTime =
                store.lastRequest / 1000 + logoutAfterMinutes * 60
            const now = Math.floor(Date.now() / 1000)
            return Math.floor(logoutTime - now)
        },
        /**
         * Checks if the current full route includes the given route.
         * @param route
         * @return {boolean}
         */
        isRoute(route) {
            return useRoute().path.includes(route)
        },
        logout() {
            service.logout()
        },
    },
}
</script>

<style scoped>
.navbar-brand {
    text-decoration: none !important;
    color: black;
    font-weight: bold;
    margin-right: 2rem !important;
}

.nav-item {
    margin-right: 0.5rem;
}

.router-link-active {
    color: black !important;
    text-decoration: underline;
}
</style>
