import * as Sentry from "@sentry/vue"

import { createApp } from "vue"

import App from "./App.vue"
import { router } from "./router"
import LoginService from "@/services/user.service"

const app = createApp(App)

// if sentry dns is set, initialize sentry
if (process.env.VUE_APP_SENTRY_DNS) {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DNS,
        environment: process.env.VUE_APP_ENV,
        release: process.env.VUE_APP_VERSION,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: [
                    "localhost",
                    "gimcon-workforce.com",
                    /^\//,
                ],
            }),
        ],
        ignoreErrors: [/Request failed with status code 401/im],
        denyUrls: [
            // 401 from unauthenticated users
            /api\/users\/whoami/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
        ],
        tracesSampleRate: 1.0,
    })
}

LoginService.whoami()
    .catch(() => {
        // ignore
    })
    .finally(() => {
        app.use(router).mount("#app")
    })

export default app
