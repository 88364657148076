import axios from "axios"

import { store } from "@/services/store.service"

// create an axios instance
const instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    withCredentials: true,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
    },
})

// add a response interceptor, to update the lastRequest timestamp
// we will use this to display a warning, if the user is inactive for a long time
instance.interceptors.response.use(function (response) {
    store.lastRequest = new Date().getTime()
    return response
})

export default instance
