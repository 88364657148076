<template>
    <div
        class="container d-flex align-items-center justify-content-center h-100"
    >
        <div class="row w-100">
            <div class="col-12 col-md order-1 order-md-0">
                <div class="d-flex align-items-center w-100 h-100">
                    <form
                        class="w-100"
                        data-test="login-form"
                        @submit.prevent="submitLogin"
                    >
                        <h1 class="m-0 position-relative">
                            <img
                                :src="require('@/assets/gimconworkforce.png')"
                                alt="Gimcon Workforce Logo"
                                height="40"
                                class="d-inline-block position-relative me-1"
                                style="top: -4px"
                            />
                            <span class="text-uppercase d-inline-block me-1"
                                >Gimbel</span
                            >
                            <small class="text-secondary">workforce</small>
                            <EnvironmentBadge
                                class="d-inline-block position-absolute"
                                style="top: 10px; right: 8px"
                                css-style="font-size: 1rem"
                            />
                        </h1>
                        <hr class="my-4" />
                        <div
                            v-if="error_message"
                            class="alert alert-info text-capitalize"
                        >
                            {{ error_message }}
                        </div>
                        <div class="mb-3">
                            <label
                                for="username"
                                class="form-label"
                                >Benutzername</label
                            >
                            <input
                                id="username"
                                v-model="username"
                                type="text"
                                name="username"
                                placeholder="Benutzername"
                                autocomplete="username"
                                required
                                class="form-control"
                            />
                        </div>
                        <div class="mb-5">
                            <label
                                for="password"
                                class="form-label"
                                >Password</label
                            >
                            <input
                                id="password"
                                v-model="password"
                                type="password"
                                name="password"
                                placeholder="Passwort"
                                autocomplete="current-password"
                                required
                                class="form-control"
                            />
                        </div>
                        <button
                            type="submit"
                            class="btn btn-outline-primary btn-lg mt-1"
                        >
                            Anmelden
                        </button>
                    </form>
                </div>
            </div>
            <div
                class="col-12 col-md order-0 d-flex align-items-center justify-content-center"
            >
                <img
                    :src="require('@/assets/rgb_gross_logo.jpg')"
                    class="d-block mx-auto img-fluid"
                    style="max-height: 400px"
                    alt="Gimbel Consulting"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EnvironmentBadge from "@/components/EnvironmentBadge.vue"
import { default as service } from "@/services/user.service"

export default {
    name: "LoginView",
    components: { EnvironmentBadge },
    data() {
        return {
            error_message: "",
            password: null,
            username: null,
        }
    },
    methods: {
        /**
         * Send login to backend and process result.
         */
        submitLogin() {
            service
                .login({
                    username: this.username,
                    password: this.password,
                })
                .then(() => window.location.reload())
                .catch((error) => {
                    console.error(error)
                    this.error_message = error.response?.data
                })
        },
    },
}
</script>

<style scoped></style>
