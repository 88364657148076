export const UserRoles = Object.freeze({
    WF_USER: "workforce.user",
    WF_ADMIN: "workforce.admin",
    DB_USER: "db.user",
    DB_MANAGEMENT: "db.management",
    ATC_USER: "atc.user",
    ATC_MANAGEMENT: "atc.management",
    SITEACCEPTANCE_USER: "siteacceptance.user",
    SITEACCEPTANCE_MANAGEMENT: "siteacceptance.management",
    SITEACCEPTANCE_HOL_EXTERN: "siteacceptance.hol_extern",
    SF_USER: "sf.user",
    DFMG_USER: "dfmg.user",
    NOTE_USER: "note.user",
    ONSITE_USER: "onsite.user",
    ONSITE_AUDITOR: "onsite.auditor",
    VERSATEL_USER: "versatel.user",
    HESA_USER: "hesa.user",
})
