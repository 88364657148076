import * as Sentry from "@sentry/vue"

import http from "../utils/http"
import { store } from "@/services/store.service"

/**
 * Service
 */
export default {
    /**
     * Send login request to backend and if successful,
     * creates a browser session.
     * @param data
     * @return {Promise}
     */
    login: (data) => {
        return http.post("/users/login", data).then((response) => {
            if (response.data.user) {
                Sentry.setUser({
                    id: response.data.user.id,
                    username: response.data.user.username,
                    email: response.data.user.email,
                })
            }
        })
    },
    /**
     * Send logout request and clears browser session.
     * @return {Promise}
     */
    logout: () => {
        return http.get("/users/logout").then(() => {
            store.user = false
            store.settings = {}
            store.version = null

            Sentry.setUser(null)
        })
    },
    /**
     * Fetches user data from backend.
     * @return {Promise}
     */
    whoami: () => {
        return http.get("/users/whoami").then((response) => {
            store.user = response.data.user
            store.settings = response.data.settings
            store.version = response.data.version

            Sentry.setUser({
                id: response.data.user.id,
                username: response.data.user.username,
                email: response.data.user.email,
            })
        })
    },
    /**
     * Send user settings changed to backend.
     * @param {string} key
     * @param {*} value
     * @return {Promise}
     */
    settingsSet: (key, value) => {
        store.settings = store.settings ?? {}
        store.settings[key] = value

        return http.post(`/users/settings/${key}`, {
            value,
        })
    },
    /**
     * Reads user settings from local store.
     * @param {string} key
     * @return {*|boolean}
     */
    settingsGet: (key) => {
        return (store.settings && store.settings[key]) ?? false
    },
}
