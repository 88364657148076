import _ from "lodash"
import { createWebHistory, createRouter } from "vue-router"

import { store } from "@/services/store.service"
import { UserRoles } from "@/user_roles"

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("./views/HomeView.vue"),
        meta: { roles: [UserRoles.WF_USER] },
    },
    {
        path: "/site-acceptance/claim-tasks",
        name: "tasks-list",
        component: () => import("./views/site.acceptance/TasksList.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_USER] },
    },
    {
        path: "/site-acceptance/claim-tasks/:projectId",
        name: "claim-tasks-grid",
        component: () => import("./views/site.acceptance/OpenTasks.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_USER] },
    },
    {
        path: "/site-acceptance/my-tasks/:projectId",
        name: "my-tasks-grid",
        component: () => import("./views/site.acceptance/MyTasks.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_USER] },
    },
    {
        path: "/site-acceptance/my-tasks-hol-extern/:projectId/:flag",
        name: "my-tasks-grid-hol-extern",
        component: () => import("./views/site.acceptance/HolExternView.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_HOL_EXTERN] },
    },
    {
        path: "/site-acceptance/subscribe",
        name: "subscribe-lists",
        component: () => import("./views/site.acceptance/SubscribeLists.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_USER] },
    },
    {
        path: "/site-acceptance/management",
        name: "s.a/management",
        component: () => import("./views/site.acceptance/MgmtView.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_MANAGEMENT] },
    },
    {
        path: "/site-acceptance/history",
        name: "history",
        component: () => import("./views/site.acceptance/HistoryView.vue"),
        meta: { roles: [UserRoles.SITEACCEPTANCE_USER] },
    },
    {
        path: "/atc-trackinglist",
        name: "atc-trackinglist",
        component: () => import("./views/atc.trackinglist/AtcTrackinglist.vue"),
        meta: { roles: [UserRoles.ATC_USER] },
    },
    {
        path: "/db-trackinglist",
        name: "db-trackinglist",
        component: () => import("./views/db.trackinglist/DbTrackinglist.vue"),
        meta: { roles: [UserRoles.DB_USER] },
    },
    {
        path: "/db-trackinglist/:trackinglistId/plandirectory",
        name: "db-plandirectory",
        component: () => import("./views/db.trackinglist/DbPlandirectory.vue"),
        meta: { roles: [UserRoles.DB_USER] },
    },
    {
        path: "/sonder-freigaben",
        name: "sonder-freigaben",
        component: () => import("./views/sonder.freigaben/SonderFreigaben.vue"),
        meta: { roles: [UserRoles.SF_USER] },
    },
    {
        path: "/dfmg-trackinglist/trackinglist-offsite",
        name: "trackinglist-offsite",
        component: () =>
            import("./views/dfmg.trackinglist/DfmgOffTrackinglist.vue"),
        meta: { roles: [UserRoles.DFMG_USER] },
    },
    {
        path: "/dfmg-trackinglist/trackinglist-onsite",
        name: "trackinglist-onsite",
        component: () =>
            import("./views/dfmg.trackinglist/DfmgOnTrackinglist.vue"),
        meta: { roles: [UserRoles.DFMG_USER] },
    },
    {
        path: "/dfmg-trackinglist/trackinglist-mastwagen",
        name: "trackinglist-mastwagen",
        component: () =>
            import("./views/dfmg.trackinglist/DfmgMastwagenTrackinglist.vue"),
        meta: { roles: [UserRoles.DFMG_USER] },
    },
    {
        path: "/nokia-trackinglist/oran113",
        name: "nokia-oran113",
        component: () => import("@/views/nokia-trackinglist/NokiaOran113.vue"),
        meta: { roles: [UserRoles.NOTE_USER] },
    },
    {
        path: "/nokia-trackinglist/health-safety",
        name: "nokia-health-safety",
        component: () =>
            import("@/views/nokia-trackinglist/NokiaHealthSafety.vue"),
        meta: { roles: [UserRoles.NOTE_USER] },
    },
    {
        path: "/nokia-trackinglist/mw-audits",
        name: "nokia-mw-audits",
        component: () => import("@/views/nokia-trackinglist/NokiaMwAudits.vue"),
        meta: { roles: [UserRoles.NOTE_USER] },
    },
    {
        path: "/nokia-trackinglist/oran3000",
        name: "nokia-oran3000",
        component: () => import("@/views/nokia-trackinglist/NokiaOran3000.vue"),
        meta: { roles: [UserRoles.NOTE_USER] },
    },
    {
        path: "/onsite-planning/plan",
        name: "onsite-plan",
        component: () => import("./views/onsite.plan/OnsitePlanSummary.vue"),
        meta: { roles: [UserRoles.ONSITE_USER] },
    },
    {
        path: "/onsite-planning/history",
        name: "onsite-plan-history",
        component: () => import("./views/onsite.plan/OnsitePlanHistory.vue"),
        meta: { roles: [UserRoles.ONSITE_USER] },
    },
    {
        path: "/onsite-planning/plan/:planId/map",
        name: "onsite-map",
        component: () => import("./views/onsite.plan/OnsitePlanMapView.vue"),
        meta: { roles: [UserRoles.ONSITE_USER] },
    },
    {
        path: "/onsite-planning/plan/:planId/table",
        name: "onsite-table",
        component: () => import("./views/onsite.plan/OnsitePlanTableView.vue"),
        meta: { roles: [UserRoles.ONSITE_USER] },
    },
    {
        path: "/onsite-planning/ran",
        name: "onsite-ran",
        component: () => import("./views/onsite.trackinglist/OnsiteRan.vue"),
        //meta: { roles: [UserRoles.ONSITE_USER] }
    },
    {
        path: "/onsite-planning/mw",
        name: "onsite-mw",
        component: () => import("./views/onsite.trackinglist/OnsiteMW.vue"),
        //meta: { roles: [UserRoles.ONSITE_USER] }
    },
    {
        path: "/onsite-planning/nrg",
        name: "onsite-nrg",
        component: () => import("./views/onsite.trackinglist/OnsiteNRG.vue"),
        // meta: { roles: [UserRoles.ONSITE_USER] },
    },
    {
        path: "/onsite-planning/ranohneinfra",
        name: "onsite-ranohneinfra",
        component: () =>
            import("./views/onsite.trackinglist/OnsiteRanOhneInfra.vue"),
        //meta: { roles: [UserRoles.ONSITE_USER] }
    },
    {
        path: "/versatel",
        name: "versatel",
        component: () => import("./views/versatel.trackinglist/Versatel.vue"),
        meta: { roles: [UserRoles.VERSATEL_USER] },
    },
    {
        path: "/health-safety/ubersicht",
        name: "hesa.ubersicht",
        component: () => import("./views/health.safety/UbersichtView.vue"),
        meta: { roles: [UserRoles.HESA_USER] },
    },
    {
        path: "/health-safety/unterweisungsliste",
        name: "hesa.unterweisungsliste",
        component: () =>
            import("./views/health.safety/UnterweisungslisteView.vue"),
        meta: { roles: [UserRoles.HESA_USER] },
    },
    {
        path: "/health-safety/psa",
        name: "hesa.psa",
        component: () => import("./views/health.safety/PsaView.vue"),
        meta: { roles: [UserRoles.HESA_USER] },
    },
    {
        path: "/health-safety/werkzeug",
        name: "hesa.werkzeug",
        component: () => import("./views/health.safety/WerkzeugView.vue"),
        meta: { roles: [UserRoles.HESA_USER] },
    },
    {
        path: "/health-safety/abnahmen",
        name: "hesa.abnahmen",
        component: () => import("./views/health.safety/AbnahmenView.vue"),
        meta: { roles: [UserRoles.HESA_USER] },
    },
    {
        path: "/administration/user",
        name: "administration-user",
        component: () => import("./views/administration/UserDashboard.vue"),
        meta: { roles: [UserRoles.WF_ADMIN] },
    },
    {
        path: "/administration/milestones-dashboard",
        name: "milestones-dashboard",
        component: () =>
            import(
                "./views/administration/MilestonesDashboard/MilestonesDashboard.vue"
            ),
        meta: { roles: [UserRoles.WF_ADMIN] },
    },
    // ... 404
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("./views/NotFound.vue"),
    },
]

// local debug route
if (process.env.VUE_APP_ENV === "local") {
    routes.push({
        path: "/debug",
        component: () => import("./views/DebugView.vue"),
    })
}

// local sentry test route
if (process.env.VUE_APP_ENV === "local" && process.env.VUE_APP_SENTRY_DNS) {
    routes.push({
        path: "/debug-sentry",
        component: () => import("./views/DebugSentryView.vue"),
    })
}

const Router = createRouter({
    history: createWebHistory(),
    routes,
})

/**
 * Global navigation guard.
 */
Router.beforeEach(async (to) => {
    // Redirect to home if user is not allowed to access the route.
    if (_.difference(to.meta.roles, store.user?.roles).length > 0) {
        return false
    }
})

export const router = Router
