import moment from "moment/moment"

export const ui = {
    /**
     * Checks if it's a public holiday and then changes the UI.
     */
    checkForPublicHolidays() {
        // add more holiday events here ...
        if (this.isItChristmastime() || this.isItEasterntime()) {
            this.setPublicHoliday()
        }
    },
    /**
     * Check if it's the christmastime.
     * @return {boolean}
     */
    isItChristmastime() {
        // check with moment.js if the current date is during christmastime, but ignore the year
        const startOfChristmas = moment().month("December").date(1)
        const endOfChristmas = moment().month("December").date(26)
        return moment().isBetween(startOfChristmas, endOfChristmas, null, "[]")
    },
    isItEasterntime() {
        // check with moment.js if the current date is during christmastime, but ignore the year
        const startOfEastern = moment().month("March").date(1)
        const endOfEastern = moment().month("April").date(7)
        return moment().isBetween(startOfEastern, endOfEastern, null, "[]")
    },
    isItUEFAtime() {
        // check with moment.js if the current date is during christmastime, but ignore the year
        const startOfUEFA = moment("2024-06-06")
        const endOfUEFA = moment("2024-07-14")
        return moment().isBetween(startOfUEFA, endOfUEFA, null, "[]")
    },
    /**
     * Toggles the Christmastime class on the element with id 'app'.
     * Adds the class 'its-christmastime' if it's not present, and removes it if it's already present.
     * @return {void} - Does not return any value.
     */
    toggleChristmastime() {
        document.querySelector("#app").classList.toggle("its-christmastime")
    },
    setPublicHoliday() {
        document.querySelector("#app").classList.add("its-christmastime")
    },
    removePublicHoliday() {
        document.querySelector("#app").classList.remove("its-christmastime")
    },
}
